import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import { device } from '../styles/config'
import isMobile from '../utils/isMobile'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${ Row }  {
    height: 100%;
    margin: 0;
  }

  video {
    width: 100%;
  }

  @media ${ device.sm } {
    position: static;
    padding-bottom: 1rem;
  }
`

const TextContainer = styled(Col)`
  font-family: 'GT America', sans-serif;
  
  p {
    padding-bottom: 1rem;
  }

  a {
    text-decoration: underline;
  }
`

const Simon = ({ data: { datoCmsSimon: { text } } }) => (
  <Container>
    <Row center='xs' middle='xs'>
      <Col xs={8} sm={4}>
        <>
          <video
            src="https://www.botanicalagency.com/simon.mp4"
            muted={true}
            webkit-playsinline="true"
            playsInline={true}
            loop={true}
            autoPlay={true}
          />
        </>
      </Col>
      <TextContainer xs={8} sm={4} md={3} dangerouslySetInnerHTML={{ __html: text }}/>
    </Row>
  </Container>
)

export const query = graphql`
  query SimonQuery {
    datoCmsSimon {
      text
      video {
        providerUid
      }
    }
  }
`

export default Simon
